$('.reviews-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,

    responsive: [{
        breakpoint: 767,
        settings: {
        slidesToShow: 1,    
        adaptiveHeight: false,
        }
    }
]}
)

$('.single-gallery').slick({
    adaptiveHeight: true,
    prevArrow: '<button class="btn dark icon icon-left-arrow prev"></button>',
    nextArrow: '<button class="btn dark icon icon-right-arrow next"></button>',
})

$(document).on('click', '.arrows[data-slider] div', function(){
    var elem = $(this),
        parent = elem.parent(),
        slider = parent.data('slider'),
        slider = $('.' + slider),
        index = elem.index();

    if(index){
        slider.slick('slickNext');
    }else{
        slider.slick('slickPrev');
    }
})
function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll')
    }else{
        $('header.site-header').removeClass('scroll')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$('.open-menu').click(function(){
    const elem = $(this)
    const menu = $('.site-menu')
    const body = $('body')
    const header = $('header.site-header')

    if(body.hasClass('menu-opened')){
        elem.removeClass('icon-close-light').addClass('icon-menu')
        body.removeClass('menu-opened')
        header.removeClass('white')
        menu.stop().fadeOut(300)
    }else{
        elem.addClass('icon-close-light').removeClass('icon-menu')
        body.addClass('menu-opened')
        header.addClass('white')
        menu.stop().fadeIn(300)
    }

})
const section = $('section.services')
const cursor = document.getElementById('cursor')
const points = $('.services .point, .services .hide-cursor')

window.addEventListener('mousemove', e => {
    const top = e.clientY - (cursor.offsetHeight / 2)
    const left = e.clientX - (cursor.offsetWidth / 2)

    cursor.style.top = top + 'px'
    cursor.style.left = left + 'px'
})

points.on('mouseenter', function(){
    const cursor = $('#cursor')
    const elem = $(this)
    const name = elem.data('name')
    const text = elem.data('content')
    const nameElem = cursor.find('.name h6')
    const textElem = cursor.find('.name p')

    if(elem.hasClass('hide-cursor')){
        nameElem.text('')
        textElem.html('')
        cursor.addClass('hide')
    }else{
        nameElem.text(name)
        textElem.html(text)
        cursor.addClass('show')
    }
})

points.on('mouseleave', function(){
    const cursor = $('#cursor')

    cursor.removeClass('show hide')
})


section.on('mouseenter', function(){
    $(cursor).addClass('active')
})

section.on('mouseleave', function(){
    $(cursor).removeClass('active')
})